import "./Footer.scss";
import headerLogo from "../../assets/all-images/mona_logo.png";

const Footer = () => {
    return (
      <footer>
        <div className="logo">
          <img src={headerLogo} alt="Logo" />
        </div>
        <div className="contact">
          <h2>Bize Ulaşın</h2>
          <p>Instagram:@monapsikolojiegitim</p>
          <p>Telefon:+90 551 384 79 01</p>
        </div>
        <div className="shift">
          <h2>Çalışma Saatleri</h2>
          <ul>
            <li>Hafta İçi -- 09.00-22.00</li>
            <li>Hafta Sonu -- 09.00-20.00</li>
          </ul>
        </div>
        <div className="address">
          <h2>Adresimiz</h2>
          <ul>
            <p>Güvenevler, 1943. Sk. Alpagut Apt Kat:1 D:1, 33140 Yenişehir/Mersin</p>
          </ul>
        </div>
      </footer>
    );
  }
  
  export default Footer;