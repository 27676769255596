import React, { useState, useEffect, useRef } from 'react';
import './HomeSlider.scss';
import { servicesData } from "../../assets/data/servicesData";
import { Link } from 'react-router-dom';

const HomeSlider = () => {
    const [activeIndex, setActiveIndex] = useState(0);
    const timeoutRef = useRef(null);
    const items = servicesData;

    const resetAutoSlide = () => {
        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
        }
        timeoutRef.current = setTimeout(() => {
            setActiveIndex((prev) => (prev + 1) % items.length);
        }, 5000);
    };

    useEffect(() => {
        resetAutoSlide();
        return () => clearTimeout(timeoutRef.current);
    }, [activeIndex]);

    const handlePrev = () => {
        setActiveIndex(activeIndex === 0 ? items.length - 1 : activeIndex - 1);
        resetAutoSlide();
    };

    const handleNext = () => {
        setActiveIndex((activeIndex + 1) % items.length);
        resetAutoSlide();
    };

    const handleThumbnailClick = (index) => {
        setActiveIndex(index);
        resetAutoSlide();
    };

    return (
        <div className="slider">
            <div className="list">
                {items.map((item, index) => (
                    <div key={index} className={`item ${index === activeIndex ? 'active' : ''}`}>
                        <img src={item.imgUrl} alt={item.header} />
                        <div className="content">
                            <h2>{item.header}</h2>
                            <Link className='details' to={`/services/${item.id}`}>Detaylar</Link>
                        </div>
                    </div>
                ))}
            </div>
            <div className="arrows">
                <button id="prev" onClick={handlePrev}>
                    &lt;
                </button>
                <button id="next" onClick={handleNext}>
                    &gt;
                </button>
            </div>
            <div className="thumbnail">
                {items.map((item, index) => (
                    <div
                        key={index}
                        className={`item ${index === activeIndex ? 'active' : ''}`}
                        onClick={() => handleThumbnailClick(index)}
                    >
                        <img src={item.imgUrl} alt={item.header} />
                        <div className="content">{item.header}</div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default HomeSlider;
