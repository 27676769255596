import { useState, useEffect } from "react";
import { FaInstagram } from "react-icons/fa";
import { IoCallOutline } from "react-icons/io5";
import { FaWhatsapp } from "react-icons/fa";
import "./ShortContact.scss";

const ShortContact = () => {
  const [isBottom, setIsBottom] = useState(false);

  useEffect(() => {
    const onScroll = () =>
      setIsBottom(window.innerHeight + window.scrollY >= document.body.scrollHeight);

    window.addEventListener("scroll", onScroll);

    return () => window.removeEventListener("scroll", onScroll);
  }, []);

  return (
    <div className={isBottom ? 'bottom-container' : 'icon-container'}>
      <div className='icon-div'>
        <a href="https://wa.me/905513847901?text=Merhabalar,%20rezervasyon%20yapmak%20istiyorum" target='_blank' rel='noreferrer'>
          <h2><FaWhatsapp/></h2>
        </a>
      </div>
      
      <div className='icon-div'>
        <a href="https://www.instagram.com/monapsikolojiegitim/" target='_blank' rel='noreferrer'>
          <h2><FaInstagram/></h2>
        </a>
      </div>

      <div className='icon-div phone'>
        <a href="tel:0 551 384 79 01">
          <h2><IoCallOutline/></h2>
        </a>
      </div>
    </div>
  );
}

export default ShortContact;
