import './Contact.scss';
import { FaMapLocationDot } from "react-icons/fa6";
import { FaInstagram } from "react-icons/fa";
import { MdOutlineContactPhone } from "react-icons/md";
import { FaWhatsapp } from "react-icons/fa";


const Contact = () => {
  return (
    <div className='container'>
      <div>
        <h1>İletişim</h1>
        <h4>Tek tıkla bize ulaşabilirsiniz</h4>
      </div>

      <div className='icon_container'>
        <div className='icon_div'>
          <a href="https://wa.me/905513847901?text=Merhabalar,%20rezervasyon%20yapmak%20istiyorum" target='_blank' rel='noreferrer'>
            <h2><FaWhatsapp/></h2>
          </a>
          <h4>Whatsapp</h4>
          <p>Bize Mesaj Atın</p>
        </div>
        
        <div className='icon_div'>
          <a href="https://www.instagram.com/monapsikolojiegitim/" target='_blank' rel='noreferrer'>
            <h2><FaInstagram/></h2>
          </a>
            <h4 className='insta_link'>@monapsikolojiegitim</h4>
          <p>Instagram'da Bizi Takip Edin!</p>
        </div>

        <div className='icon_div'>
          <a href="tel:0 551 384 79 01">
            <h2><MdOutlineContactPhone/></h2>
          </a>
          <h4>Bizi Arayın</h4>
          <p>+90 551 384 79 01</p>
        </div>

        <div className='icon_div'>
          <a href="https://www.google.com/maps/place/Mona+Psikoloji+E%C4%9Fitim/@36.7860312,34.5831663,14z/data=!4m6!3m5!1s0xa4e0a3bf58717c11:0x175b4c9edc904fe0!8m2!3d36.7861171!4d34.5847113!16s%2Fg%2F11sbbnwvmr?entry=ttu" target='_blank' rel='noreferrer'>
            <h2><FaMapLocationDot /></h2>
          </a>
          <h4>Adresimiz</h4>
          <p>Güvenevler, 1943. Sk. Alpagut Apt Kat:1 D:1</p>
          <p>33140 Yenişehir/Mersin</p>
        </div>

      </div>
      <div className='location_container'>
        <h2>Haritada Konumumuz</h2>
        <iframe className='location' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3195.3515231007627!2d34.58213637626922!3d36.78612136849233!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xa4e0a3bf58717c11%3A0x175b4c9edc904fe0!2sMona%20Psikoloji%20E%C4%9Fitim!5e0!3m2!1str!2str!4v1706542697623!5m2!1str!2str" referrerpolicy="no-referrer-when-downgrade" rel='noreferrer' title='monaLocation'></iframe>
      </div>
    </div>
  )
}

export default Contact

