import TeamCard from "../../components/TeamCard/TeamCard";
import { teamData } from "../../assets/data/teamData";
import "./OurTeam.scss";

const OurTeam = () => {

  return (
    <div className="ourteam-container">
      <h1>Ekibimiz</h1>
      <div className='card-container'>
        {teamData.map((item, index)=> <TeamCard {...item} key={index}/> )}
      </div>
    </div>
  )
}

export default OurTeam