import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { teamData } from "../../assets/data/teamData";
import "./TeamCardSlider.scss";

const TeamCardSlider = () => {
  const [index, setIndex] = useState(0);
  const [isMouseOver, setIsMouseOver] = useState(false);
  const filteredCards = teamData.filter(card => card.id !== 1 && card.id !== 8);
  const cards = filteredCards;

  const mod = (n, m) => {
    let result = n % m;
    return result >= 0 ? result : result + m;
  };

  useEffect(() => {
    let timer;
    if (!isMouseOver) {
      timer = setTimeout(() => {
        setIndex((index + 1) % cards.length);
      }, 1750);
    }
    return () => clearTimeout(timer);
  }, [index, isMouseOver, cards]);

  const handleMouseOver = () => {
    setIsMouseOver(true);
  };

  const handleMouseOut = () => {
    setIsMouseOver(false);
  };

  return (
    <div className="slide-container">
      <h1>EKİBİMİZ</h1>
      <div className="carousel">
        {cards.map((item, i) => {
          const indexLeft = mod(index - 1, cards.length);
          const indexRight = mod(index + 1, cards.length);

          let className = "card";

          if (i === index) {
            className = "card card--active";
          } else if (i === indexRight) {
            className = "card card--right";
          } else if (i === indexLeft) {
            className = "card card--left";
          } else className = "card";

          return (
            <Link to={`/ourteam/${item.id}`} key={item.id} style={{textDecoration:"none"}} onMouseOver={handleMouseOver} onMouseOut={handleMouseOut}>
              <div key={item.id} className={className} >
                <img
                  src={item.imgUrl}
                  alt={item.name}
                  ></img>
                  <h2>{item.name}</h2>
                  <p>{item.title}</p>
              </div>
            </Link>
          );
        })}
      </div>
    </div>
  );
}

export default TeamCardSlider;
