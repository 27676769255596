import React from 'react';
import "./ClinicGallery.scss";

const ClinicGallery = ({data}) => {

  return (
    <div className='clinic-container'>
      <h1><i>KLİNİĞİMİZ</i></h1>
      <div className='photo-container'>
        {data.map((item, id) => (
            <div className="clcard" key={id}>
                <img src={item.img} alt="clinic_photos" />
                <div className='clcard__head'>
                  {item.title}
                </div>
            </div>
        ))}    
      </div>

    </div>
  )
}

export default ClinicGallery