import { Link } from 'react-router-dom';
import "./Home.scss";
import TeamCardSlider from '../../components/TeamCardSlider/TeamCardSlider';
import AboutUs from '../../components/AboutUs/AboutUs';
import ClinicGallery from '../../components/ClinicGallery/ClinicGallery';
import { slides } from "../../assets/data/slides";
import { teamData } from '../../assets/data/teamData';
import HomeSlider from '../../components/HomeSlider/HomeSlider';
import AllMona from "../../assets/all-images/homePhotos/AllMona.JPG";

const ownerCard = teamData.find(item => item.id === 1);

const Home = () => {
  return (
    <>
      
      <div className='home-container'>
        <section className='first-container'>
          <HomeSlider />
        </section>

        <div className='second-container'>
          <div className="about-us">
            <AboutUs />
          </div>
          <div className='owner-card'>
            <img src={ownerCard.imgUrl} alt={`${ownerCard.name} - ${ownerCard.title}`} loading="lazy" />
            <h2>{ownerCard.name}</h2>
            <h4>{ownerCard.title}</h4>
            <Link className='owner-but' to="/ourteam/1" title="Şahnur Yaman hakkında detaylı bilgiye ulaşın">
              Detaylı Bilgi
            </Link>
          </div>
        </div>

        <div className="third-container">
          <TeamCardSlider />
        </div>

        <div className='home-photo'>
          <img src={AllMona} alt="Mona Psikoloji Ofis Fotoğrafı" loading="lazy" />
        </div>

        <div className='fifth-container'>
          <ClinicGallery data={slides} />
        </div>
      </div>
    </>
  );
};

export default Home;
