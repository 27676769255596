import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import "./Blog.scss";

const Blog = () => {
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

 const BASE_URL = process.env.REACT_APP_BASE_URL;

  useEffect(() => {
    axios.get(BASE_URL + "blog/all")
      .then((response) => {
        setPosts(response.data);
        setLoading(false);
      })
      .catch((err) => {
        setError(err.message);
        setLoading(false);
      });
  }, [BASE_URL]);

  if (loading) return <div className="blog-loading">Loading...</div>;
  if (error) return <div className="blog-error">Error: {error}</div>;

  return (
    <div className="blog-main-container">
      <h1>Blog Yazılarımız</h1>
      <ul className="blog-card-grid">
        {posts.map((post) => (
          <li key={post._id} className="blog-card">
            <Link to={`/blog/${post._id}`}>
              <div className="blog-card__background" style={{ backgroundImage: `url(${BASE_URL}uploads/${post.coverImage})` }}></div>
              <div className="blog-card__content">
                <h2 className="blog-card__heading">{post.title}</h2>
                {post.createdBy && (
                  <div className="blog-author-details">
                    {post.createdBy.profileImageURL && (
                      <img
                        className="blog-author-photo"
                        src={`${BASE_URL.replace(/\/+$/, '')}${post.createdBy.profileImageURL}`}
                        alt="author_image"
                      />
                    )}
                    <span>{post.createdBy.fullName}</span>
                  </div>
                )}
              </div>
            </Link>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Blog;
