import "./TeamCard.scss";
import { Link } from "react-router-dom";
import { CiSquareMore } from "react-icons/ci";


const TeamCard = ({ id, imgUrl, name, title}) => {


  return (
    <Link to={`/ourteam/${id}`} style={{textDecoration:"none"}} className="link-container">
    <div id={id} className={"cards"}>
      <div className="caption">
        <h2>{name}</h2>
        <div className="icons">
          <a href="#">Daha fazla bilgi<CiSquareMore /></a> 
        </div>
      </div>
      <img src={imgUrl} alt="portrait" />
      <div className="title">{title}</div>
    </div>
    </Link>
  );
};

export default TeamCard