import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Link, useParams } from 'react-router-dom';
import "./BlogDetails.scss";

const BlogDetails = () => {
  const { id } = useParams();
  const [post, setPost] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const BASE_URL = process.env.REACT_APP_BASE_URL;

  useEffect(() => {
    console.log(`Fetching blog details for ID: ${id}`);
    axios.get(`${BASE_URL}blog/detail/${id}`)
      .then((response) => {
        console.log('Blog data fetched:', response.data);
        setPost(response.data);
        setLoading(false);
      })
      .catch((err) => {
        console.error('Error fetching blog details:', err);
        setError(err.message);
        setLoading(false);
      });
  }, [id, BASE_URL]);

  if (loading) return <div className="blog-loading">Loading...</div>;
  if (error) return <div className="blog-error">Error: {error}</div>;

  if (!post) return <div>No blog post found</div>;

  const profileImageURL = `${BASE_URL.replace(/\/+$/, '')}${post.createdBy.profileImageURL}`;

  return (
    <div className='blog-dt-container'>
      <div className='blog-details'>
      <h1>{post.title}</h1>
      {post.coverImage && <img src={`${BASE_URL}uploads/${post.coverImage}`} alt="blog_image"/>}
      <div className='blog-body'>{post.body}</div>
        <div className='author-details'>
          {post.createdBy.profileImageURL && (
          <img src={profileImageURL} alt="author_image"/>
          )}
            <span className='author-fullname'>{post.createdBy.fullName} tarafından yazıldı.</span>
        </div>
      </div>
    </div>
  );
};

export default BlogDetails;
