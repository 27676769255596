import React, { useState } from "react";
import NavbarLogo from "../../assets/all-images/mona_logo.png";
import "./Navbar.scss";
import { Link, NavLink } from "react-router-dom";
import { RiArrowDropDownLine } from "react-icons/ri";
import { servicesData } from "../../assets/data/servicesData";

export const Navbar = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [dropIsOpen, setDropIsOpen] = useState(false);
  const [educationIsOpen, setEducationIsOpen] = useState(false);
  const [navbar, setNavbar] = useState(false);

  const handleMenuOpen = () => {
    setMenuOpen(!menuOpen);
  };

  const handleClose = () => {
    setEducationIsOpen(false);
    setDropIsOpen(false);
    setMenuOpen(false);
  }

  const changeBackground = () => {
    // console.log(window.scrollY);
    if(window.scrollY >= 40) {
      setNavbar(true);
    } else {
      setNavbar(false);
    }
  }
  
  window.addEventListener("scroll", changeBackground);

  return (
    <nav className={navbar ? 'navbar scrolled' : 'navbar'}>
      <Link to="/home" className="title">
        <img src={NavbarLogo} alt="navbarLogo" />
      </Link>
      <div className="menu" onClick={handleMenuOpen}>
        <span></span>
        <span></span>
        <span></span>
      </div>
      <ul className={menuOpen ? "open" : ""}>
        <li>
          <NavLink to="/home" onClick={handleClose}>Ana Sayfa</NavLink>
        </li>
        <li>
          <NavLink to="/ourteam" onClick={handleClose}>Ekibimiz</NavLink>
        </li>
        <li>
          <Link to="#" 
          onClick={() => dropIsOpen ? setDropIsOpen(false) : null} 
          onMouseOver={() => setDropIsOpen(true)}
          onMouseLeave={() => setDropIsOpen(false)}>
            Hizmetlerimiz <RiArrowDropDownLine />
          {dropIsOpen && (
            <ul className={dropIsOpen ? "dropOpen" : ""}>
              {servicesData.map((service) => (
                <li key={service.id} onClick={handleClose}>
                  <Link to={`/services/${service.id}`}>
                    {service.header}
                  </Link>
                </li>
              ))}
          </ul>
          )}
          </Link>
        </li>
        <li>
          <Link to="#"
          onClick={()=> educationIsOpen ? setEducationIsOpen(false) : null} 
          onMouseOver={() => setEducationIsOpen(true)} 
          onMouseLeave={() => setEducationIsOpen(false)}>
            Eğitimlerimiz <RiArrowDropDownLine />
            {educationIsOpen && (
              <ul className={educationIsOpen ? "dropOpen" : ""}>
                <li onClick={handleClose}>
                  <Link to={`educations/stgallery`}>Staj Programi</Link>
                  <Link to={`educations/mtest`}>Moxo d-CPT Dikkat Testi</Link>
                  <Link to={`educations/workshop`}>Atölye</Link>
                </li>
              </ul>
            )}
            </Link>
        </li>
        <li onClick={handleClose}>
          <NavLink to="/blog">Blog</NavLink>
        </li>
        <li onClick={handleClose}>
          <NavLink to="/contact">Bize Ulaşın</NavLink>
        </li>
      </ul>
    </nav>
  );
};