import React, { Fragment } from "react";
import Router from "../../routers/Routers.jsx";
import Footer from "../Footer/Footer.jsx";
import { Navbar } from "../Navbar/Navbar.jsx";

const Layout = () => {
  return (
    <Fragment>
        <Navbar/>
        <Router/>
        <Footer/>
    </Fragment>
  )
}

export default Layout