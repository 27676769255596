import moxo from "../../assets/all-images/moxo-photo.jpg";
import "./Mtest.scss";

const Mtest = () => {
  return (
    <div className='moxo-container'>
      <h1>MOXO d-CPT DİKKAT TESTİ</h1 >
      <div className="moxo-content">
        <img src={moxo} alt="moxo" />
        <p>MOXO dikkat performans testi 6 yaş ve üzeri kişiler için geliştirilmiş ADHD-DEHB Dikkat
        Eksikliği Hiperaktivite tanısına yardımcı olan, bilgisayar üzerinden yapılan görsel ve işitsel
        çeldiricilerin –dikkat dağıtıcıların- kullanılarak kişinin dikkat performansını ölçen bir testtir.
        Doğru tanı koymada yardımcıdır ve güvenilirliği % 90 ‘dır.
        MOXO, Dikkat Eksikliği Hiperaktivite Bozukluğunun dört ana semptomu olan dikkat eksikliği,
        zamanlama problemi, dürtüsellik ve hiperaktivitenin objektif olarak ölçülmesini sağlar.</p>
      </div>
    </div>
  )
}

export default Mtest