import React from 'react';
import { useParams } from 'react-router-dom';
import { teamData } from '../../assets/data/teamData';
import "./TeamDetails.scss";
import { FaWhatsapp } from "react-icons/fa";

const TeamDetails = () => {
  const { id } = useParams();
  const member = teamData.find((item) => item.id === parseInt(id, 10));
  const selectedMember = teamData.find((item) => item.id === parseInt(id));
  
  const { name, title, imgUrl, bookLink, linkMsg} = member;
  const whatsappMessage = encodeURIComponent(`Merhaba, ${name} ile rezervasyon yapmak istiyorum`);

  const linkedList = ["Şahnur YAMAN", "Gizem Havare"]

  return (
    <div className="team-details-container">
      <img src={imgUrl} alt={`${name}'s photo`} />
      <div className='text-content'>
        <h1>{name}</h1>
        <h3><i>{title}</i></h3>
        {selectedMember.desc.split('\n').map((paragraph, index) => (
        <p key={index}>{paragraph}</p>
        ))}
        <div class='wp-container'>
            <a href={`https://wa.me/905513847901?text=${whatsappMessage}`} target='_blank' rel='noreferrer'>
              <span>Benimle randevu oluşturun</span> <FaWhatsapp />
            </a>
        </div>
        {name && linkedList.includes(name) ? (
          <div className='wp-container'>
            <a href={`${bookLink}`} target='_blank'>{linkMsg}</a>
         </div>
        ) : (
        <></>
        )}
      </div>
    </div>
  );
};

export default TeamDetails;
