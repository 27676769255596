import React, { useState, useEffect } from 'react';
import './AboutUs.scss';

const AboutUs = () => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 720);

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 720);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleToggle = () => {
    setIsExpanded(!isExpanded);
  };

  const fullText = `Merhaba! Ben MONA. İsmim, dileklerin ve tekliğin sembolü. Burası sadece bir psikoloji kliniği değil, aynı zamanda bir içsel keşif ve dönüşüm yolculuğuna çıkma yeridir. Biz, her bireyin benzersiz hikayesini derinden önemseyen ve değerli bulan bir ekibiz. MONA olarak amacımız, her danışanın içsel potansiyelini ortaya çıkararak, baş etme becerisini yükseltmeye eşlik etmektir. "Mona" kelimesi, farklı kültürlerde "dilek", "ulaşılması yüksek arzu", "teklik", "sevgi " ve "güzel" anlamlarına gelir. MONA'da biz her bir danışanın tek ve benzersiz hikayesini dinlerken, onların dileklerini ve özlemlerini anlamaya ve onlara eşlik etmeye odaklanırız. Bizimle birlikte, daha mutlu, dengeli ve tatmin edici bir yaşamı keşfetmek için adım atın. MONA'da, sizin hikayeniz bizim önceliğimizdir. İçinizdeki dilekleri ve tekliği bulmak için birlikte çalışalım. Hadi, birlikte yeni bir başlangıca adım atalım!`;

  const summaryText = fullText.substring(0, 100) + '...';

  return (
    <div className='about-container'>
      <h1>Hikayemiz</h1>
      <p className={isMobile && !isExpanded ? 'collapsed' : 'expanded'}>
        {isMobile && !isExpanded ? summaryText : fullText}
      </p>
      {isMobile && (
        <button className='toggle-button' onClick={handleToggle}>
          {isExpanded ? 'Daha az göster' : 'Daha fazla göster'}
        </button>
      )}
    </div>
  );
}

export default AboutUs;
