import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import Home from "../pages/Home/Home.jsx";
import Contact from "../pages/Contact/Contact.jsx";
import NotFound from "../pages/NotFound/NotFound.jsx";
import OurTeam from "../pages/OurTeam/OurTeam.jsx";
import TeamDetails from "../pages/TeamDetails/TeamDetails.jsx";
import ServiceDetails from "../components/ServiceDetails/ServiceDetails.jsx";
import StGallery from "../components/StGallery/StGallery.jsx";
import Workshop from "../components/Workshop/Workshop.jsx";
import Mtest from "../components/Mtest/Mtest.jsx";
import Blog from "../pages/Blog/Blog.jsx";
import BlogDetails from "../components/BlogDetails/BlogDetails.jsx";
import ScrollToTop from "../components/ScrollToTop";

function Routers() {
  return (
    <>
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<Navigate to="/home" />} />
        <Route path="/home" element={<Home />} />
        <Route path="/ourteam" element={<OurTeam />} />
        <Route path="/ourteam/:id" element={<TeamDetails />} />
        <Route path="/services/:id" element={<ServiceDetails />} />
        <Route path="/educations/stgallery" element={<StGallery />} />
        <Route path="/educations/mtest" element={<Mtest />} />
        <Route path="/educations/workshop" element={<Workshop />} />
        <Route path="/blog" element={<Blog />} />
        <Route path="/blog/:id" element={<BlogDetails />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </>
  );
}

export default Routers;
