import beklemesalonu from "../all-images/clinicPhotos/beklemesalonu.jpeg";
import birebir from "../all-images/clinicPhotos/birebir.JPG";
import ankid from "../all-images/clinicPhotos/ankid.JPG";
import cocuk from "../all-images/clinicPhotos/cocuk.JPG";
import yenidencl from "../all-images/clinicPhotos/yenidencl.JPG";
import clinic6 from "../all-images/clinicPhotos/clinic6.JPG";

export const slides = [
    {
        id:1,
        img: beklemesalonu,
        title:"Bekleme Salonu",
        details:"Lorem ipsum, dolor sit amet consectetur adipisicing elit. Voluptate fugit maxime perspiciatis minus amet officiis, ullam aliquam nesciunt doloribus porro unde labore soluta rem, quia saepe nihil neque corrupti ex.",
    },
    {
        id:2,
        img: birebir,
        title:"Seans Odası",
        details:"Lorem ipsum, dolor sit amet consectetur adipisicing elit. Voluptate fugit maxime perspiciatis minus amet officiis, ullam aliquam nesciunt doloribus porro unde labore soluta rem, quia saepe nihil neque corrupti ex.",
    },
    {
        id:3,
        img: ankid,
        title:"Oyun Odası",
        details:"Lorem ipsum, dolor sit amet consectetur adipisicing elit. Voluptate fugit maxime perspiciatis minus amet officiis, ullam aliquam nesciunt doloribus porro unde labore soluta rem, quia saepe nihil neque corrupti ex.",
    },
    {
        id:5,
        img: yenidencl,
        title:"Seans Odası",
        details:"Lorem ipsum, dolor sit amet consectetur adipisicing elit. Voluptate fugit maxime perspiciatis minus amet officiis, ullam aliquam nesciunt doloribus porro unde labore soluta rem, quia saepe nihil neque corrupti ex.",
    },
    {
        id:4,
        img: cocuk,
        title:"Oyun Odası",
        details:"Lorem ipsum, dolor sit amet consectetur adipisicing elit. Voluptate fugit maxime perspiciatis minus amet officiis, ullam aliquam nesciunt doloribus porro unde labore soluta rem, quia saepe nihil neque corrupti ex.",
    },
    {
        id:6,
        img: clinic6,
        title:"Seans Odası",
        details:"Lorem ipsum, dolor sit amet consectetur adipisicing elit. Voluptate fugit maxime perspiciatis minus amet officiis, ullam aliquam nesciunt doloribus porro unde labore soluta rem, quia saepe nihil neque corrupti ex.",
    },
    
]