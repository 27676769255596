import afis from "../all-images/stGallery/afis.jpeg";
import afis2 from "../all-images/stGallery/afis2.jpeg";
import birebirg from "../all-images/stGallery/birebirg.jpeg";
import genel from "../all-images/stGallery/genel.jpeg";
import kafis from "../all-images/stGallery/kafis.jpeg";
import meeting from "../all-images/stGallery/meeting.jpeg";
import toplannti from "../all-images/stGallery/toplannti.jpeg"
import toplanti from "../all-images/stGallery/toplanti.jpeg";
import toplantige from "../all-images/stGallery/toplantige.jpeg";
import toplantine from "../all-images/stGallery/toplantine.jpeg";

export const stgallery = [
    {
        id:1,
        src:toplanti,
        width:4,
        height:3,
    },
    {
        id:2,
        src:afis,
        width:3,
        height:5,
    },
    {
        id:3,
        src:birebirg,
        width:4,
        height:4,
    },
    {
        id:4,
        src:genel,
        width:4,
        height:3,
    },
    {
        id:5,
        src:toplannti,
        width:4,
        height:3,
    },
    {
        id:6,
        src:meeting,
        width:4,
        height:4,
    },
    {
        id:7,
        src:kafis,
        width:3,
        height:3,
    },
    {
        id:8,
        src:afis2,
        width:3,
        height:4,
    },
    {
        id:9,
        src:toplantige,
        width:4,
        height:3,
    },
    {
        id:10,
        src:toplantine,
        width:6,
        height:6,
    },
]