import "./App.scss";
import Layout from "./components/Layout/Layout";
import ShortContact from "./components/ShortContact/ShortContact";

 
function App() {
  return (
    <div className="App">
      <Layout/>
      <ShortContact/>
    </div>
  );
}

export default App;
