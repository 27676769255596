import act from "../all-images/workshop/act.jpeg";
import conv from "../all-images/workshop/conv.jpeg";
import game from "../all-images/workshop/game.jpeg";
import meeting from "../all-images/workshop/meeting.jpeg";
import onfoot from "../all-images/workshop/onfoot.jpeg";
import paper from "../all-images/workshop/paper.jpeg";

export const moxodata = [
    {
        id:1,
        src:act,
        width:3,
        height:4,
    },
    {
        id:2,
        src:conv,
        width:3,
        height:4,
    },
    {
        id:3,
        src:game,
        width:3,
        height:4,
    },
    {
        id:4,
        src:meeting,
        width:3,
        height:4,
    },
    {
        id:5,
        src:onfoot,
        width:3,
        height:4,
    },
    {
        id:6,
        src:paper,
        width:5,
        height:4,
    },
]
