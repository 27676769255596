import React from "react";
import { useParams } from "react-router-dom";
import { servicesData } from "../../assets/data/servicesData";
import "./ServiceDetails.scss";

const ServiceDetails = () => {
  const { id } = useParams();

  const selectedService = servicesData.find((service) => service.id === parseInt(id));

  return (
    <div className="service-dt-container">
      <img src={selectedService.imgUrl} alt="servicePhoto" />
      <div className="texts">
        <h2>{selectedService.header}</h2>
        {selectedService.details.split('\n').map((paragraph, index) => (
        <p key={index}>{paragraph}</p>
        ))}
      </div>
    </div>
  );
};

export default ServiceDetails;
